<template lang="pug">
v-dialog(v-model="show" :max-width="maxWidth" :fullscreen="fullscreen" v-bind="$attrs")
  v-toolbar(color="primary")
    v-toolbar-items
      v-icon(@click="show=false") close
    v-toolbar-title {{ title }}

  v-card(flat)
    v-card-text
      slot

    v-card-text.actions
      slot(name="actions")
        v-btn.ml-0(@click="show=false") Закрыть

</template>

<script>
export default {
  name: 'SmartDialog',
  props: {
    value:       { type: Boolean, required: true },
    title:       { type: String, default: '' },
    maxWidth:    { type: [String, Number], default: 700 },
    fullscreen:  { type: Boolean, default: false },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
